import React from 'react';
import styles from './error.module.css';

type IProps = {
    id: string
    className?: string
    children?: React.ReactNode
}

const Error: React.FC<IProps> = ({
    id,
    className = '',
    children,
}) => (
    <span id={id} className={`${styles.error} ${className}`} data-testid="component-error">{children}</span>
);

export default Error;
